@import "cookieconsent/src/styles/animation.css";
@import "cookieconsent/src/styles/base.css";
@import "cookieconsent/src/styles/layout.css";
@import "cookieconsent/src/styles/media.css";

.cc-window {
  line-height: 1em;

  & .cc-banner {
    padding: 15px 30px;
  }
}

.cc-window.cc-floating {
  padding: 1em 1.8em;
  width: 100%;
  max-width: 100%;
  flex-direction: row;
}
.cc-window.cc-banner {
  padding: 1em 1.8em;
  width: 100%;
  flex-direction: row;
}
.cc-floating,
.cc-banner {
  background: #222;
  box-sizing: border-box;
  color: #fff;
  font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, serif;
  font-size: 17px;

  & ::-moz-selection {
    background: #ff5e99;
    color: #fff;
    text-shadow: none;
  }

  & .cc-btn,
  & .cc-btn:visited {
    background: #bf0929 linear-gradient(rgb(193, 1, 35), rgb(127, 0, 20));
    border-radius: 5px;
    border-style: none;
    color: #fff;
    font-weight: normal;
    transition: background 200ms ease-in-out, color 200ms ease-in-out, box-shadow 200ms ease-in-out;
  }

  & .cc-btn:hover,
  & .cc-btn:active {
    background: linear-gradient(rgb(218, 1, 40), rgb(153, 0, 24));
    color: #fff;
  }

  & a,
  & a:visited {
    text-decoration: none;
    color: #31a8f0;
    transition: 200ms color;
  }

  & a:hover,
  & a:active {
    color: #b2f7ff;
  }
}

.cc-theme-classic .cc-btn:last-child {
  min-width: 120px;
}
