input:invalid,
textarea:invalid {
  background-color: #fff;
  border-radius: 1px;
  box-shadow: 0 0 5px 0;
}

input::placeholder,
textarea::placeholder {
  color: #999;
}

.placeholder {
  color: #999;
}

.hidden {
  display: none;
}

body {
  background: #bf0929 url("bg_main.jpg") repeat-x;
  color: #000;
  font-family: Verdana, Arial, Helvetica, Geneva, Swiss, SunSans-Regular, sans-serif;
  font-size: 12px;
  margin: 0;
  padding: 0;
}

/* Tabellen */

table {
  border-collapse: collapse;
  border-style: none;
  border-width: 0;
  float: left;
  margin: 0 0 15px;
  padding: 0;
  width: 100%;

  & th {
    font-weight: bold;
    padding: 0 0 2px;
    text-align: left;
    vertical-align: top;
    width: 40%;
  }

  & td {
    padding: 0 0 2px;
    text-align: left;
    vertical-align: top;
    width: 60%;
  }
}

div {
  text-align: left;

  &.bigbutton.last {
    margin: 0;
  }

  &.bigbutton.inactive {
    color: #bbb;
  }
}

img {
  border-style: none;
  vertical-align: middle;
}

/* Standard-Formate */

a {
  color: #bf0929;
  text-decoration: underline;

  &:visited {
    color: #777;
  }

  &:hover {
    color: #f1956f;
    text-decoration: underline;
  }

  &.bigbutton {
    &:hover {
      background-position: 0 -29px;
      color: #fff;
      text-decoration: none;
    }

    &.active {
      background-position: 0 -29px;
      color: #fff;
    }

    &.disabled {
      display: none;
    }

    &.last {
      margin: 0;
    }

    &.right {
      float: right;
    }
  }
}

h1,
h2 {
  color: #bf0929;
  float: left;
  font-family: Palatino, Palatino Linotype, Georgia, Times New Roman, serif;
  font-size: 23px;
  font-weight: normal;
  margin-bottom: 10px;
  margin-top: 0;
  width: 100%;
}

h2 {
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  & a.bigbutton {
    font-family: Verdana, Arial, Helvetica, Geneva, Swiss, SunSans-Regular, sans-serif;
  }
}

h3,
h4,
h5 {
  color: #bf0929;
  float: left;
  font-family: Palatino, Palatino Linotype, Georgia, Times New Roman, serif;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
  margin-top: 5px;
  width: 100%;
}

h5 {
  color: #777;
  font-size: 10px;
  margin: 20px 0 3px;
}

p {
  line-height: 16px;
  margin-bottom: 15px;
  margin-top: 0;

  &.hint {
    color: #777;
    font-size: 11px;
    line-height: 14px;
  }
}

span.hint {
  color: #777;
  font-size: 11px;
  font-style: italic;
  line-height: 14px;
  padding: 0 0 0 3px;
}

ul {
  float: left;
  margin: 0 0 15px;
  padding: 0;
  width: 100%;

  & li {
    margin: 0 0 0 18px;
    padding: 0 0 5px;
  }

  &.featurelist {
    list-style-type: none;
    margin: 0 0 5px;
    width: 360px;

    & li {
      background: url("bullet.png") 0 4px no-repeat;
      line-height: 16px;
      margin: 0;
      padding: 0 0 10px 20px;
    }
  }

  &.error_list {
    color: #bf0929;
    float: left;
    font-size: 11px;
    list-style-type: none;
    margin: 3px;
    padding: 0;
    width: 100%;

    & li {
      float: left;
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }

  /* Wichtel-Liste */
  &.grouplist {
    background-color: #fff;
    float: left;
    list-style-type: none;
    margin: 0 0 5px;
    padding: 0;
    width: 504px;

    &.nohint {
      margin: 0 0 15px;
    }

    & li {
      background: url("bg_liste.jpg") bottom repeat-x;
      border: 1px solid #efefef;
      border-bottom-color: #dfdfdf;
      float: left;
      margin: 0;
      padding: 3px 40px 4px 34px;
      position: relative;
      text-indent: -24px;
      width: 428px;

      & img.status {
        margin-right: 6px;
        padding: 2px;
        position: relative;
        top: -1px;

        &.bounced {
          padding: 0;
        }

        &.completed {
          padding: 0;
        }
      }

      & a {
        color: #000;
        text-decoration: none;

        &.show {
          text-decoration: none;
        }

        &:hover {
          color: #f1956f;
        }

        &.delete,
        &.destroy,
        &.remail,
        &.edit {
          background: url("button_delete.png") 0 0 no-repeat;
          display: block;
          float: right;
          height: 13px;
          line-height: 0;
          margin: 0;
          overflow: hidden;
          padding: 0;
          position: absolute;
          right: 10px;
          text-indent: -500px;
          top: 5px;
          width: 13px;

          &:hover {
            background-position: 0 -13px;
          }
        }

        &.remail {
          background-image: url("button_send.png");
        }

        &.edit {
          background-image: url("button_edit.png");
        }
      }
    }
  }
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.indent {
  margin-left: 25px;
}

blockquote {
  color: #f1956f;
  font-style: italic;
  margin-left: 25px;
}

hr {
  border: 0;
  color: #bbb;
  height: 1px;
}

/* Seitenaufbau */

#centerframe {
  margin: 0 auto;
  padding: 0;
  width: 600px;
}

#siteframe {
  float: left;
  margin: 0;
  padding: 0;
  width: 600px;
}

#headframe {
  background: url("bg_top.png") bottom center no-repeat;
  float: left;
  height: 214px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 600px;

  & .logo {
    background: url("logo_wichtel-o-mat.png") no-repeat 0 0 transparent;
    height: 166px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 48px;
    text-indent: -999em;
    top: 15px;
    width: 319px;
    z-index: 50;
  }
}

#menu {
  background: url("bg_menu.png") repeat-x;
  float: left;
  font-size: 11px;
  height: 26px;
  margin: 0 21px;
  overflow: hidden;
  padding: 0 28px;
  position: relative;
  width: 502px;

  & ul {
    float: left;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  & li {
    float: left;
    margin: 0;
    padding: 6px 0 0;

    & a {
      color: #777;
      padding: 0 17px 0 0;
      text-decoration: none;

      &:hover {
        color: #bf0929;
        text-decoration: none;
      }
    }

    &.act a {
      color: #bf0929;
      font-weight: bold;
      text-decoration: none;
    }
  }
}

#langswitch {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 50;

  & a {
    background: url("langswitch_en.png") 0 0 no-repeat;
    display: block;
    height: 26px;
    overflow: hidden;
    text-indent: -100em;
    width: 41px;

    &:hover {
      background: url("langswitch_en.png") 0 -26px no-repeat;
    }

    &.de {
      background: url("langswitch_de.png") 0 0 no-repeat;

      &:hover {
        background: url("langswitch_de.png") 0 -26px no-repeat;
      }
    }
  }
}

#contentframe {
  background: url("bg_content.png") center repeat-y;
  float: left;
  position: relative;
  width: 600px;

  & .content {
    background: url("bg_content_bottom.jpg") bottom center no-repeat;
    float: left;
    margin: 0 20px;
    padding: 45px 28px 15px;
    position: relative;
    width: 504px;

    & p,
    & ul.privacy {
      float: left;
      padding: 0 144px 0 0;
      width: 360px;

      &.backtomenu {
        font-size: 11px;
        margin: 10px 0;
      }
    }
  }
}

#footerframe {
  background: url("bg_bottom.png") top center no-repeat;
  float: left;
  height: 46px;
  margin: 0 0 10px;
  padding: 0;
  width: 600px;

  & .copyright {
    color: #777;
    float: right;
    font-size: 10px;
    font-weight: normal;
    height: 21px;
    line-height: 21px;
    margin: -6px 48px 0 0;
    max-width: 364px;
    padding: 0;
    white-space: nowrap;
  }

  & .likeit {
    float: left;
    height: 21px;
    margin: -6px 0 0 48px;
    width: 140px;
  }
}

/* Formulare */

fieldset,
.greybox {
  background: url("bg_fieldset.png") bottom center no-repeat;
  border: 0;
  float: left;
  margin: 0 0 15px;
  min-height: 45px;
  padding: 4px 10px 3px;
  position: relative;
  width: 484px;

  &.warning {
    background-image: url("bg_warning.png");

    & h3 {
      margin-top: 0;
    }

    & p {
      width: 460px !important;
    }
  }
}

.fieldset-top,
.greybox-top {
  background: url("bg_fieldset_top.png") top center no-repeat;
  float: left;
  height: 6px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 504px;

  &.warning {
    background-image: url("bg_warning_top.png");
  }
}

form.new_relationship {
  display: none;
}

input,
select,
textarea {
  border-style: none;
  border-width: 0;
  font-family: Verdana, Arial, Helvetica, Geneva, Swiss, SunSans-Regular, sans-serif;
  font-size: 12px;
}

input.checkbox {
  border-style: none;
}

.form-row {
  float: left;
  margin: 0 0 7px;
  padding: 0;
  width: 484px;

  &.space {
    margin: 0 0 50px;
  }

  &.special {
    display: none;
  }

  &.text {
    & .left,
    & .right,
    & .wide {
      float: left;
      margin: 0 7px 0 0;
      padding: 0;
      width: 200px;
    }

    & .wide {
      width: 400px;

      & .hint {
        clear: left;
        display: block;
        padding-top: 2px;
        width: 460px;
      }
    }

    & .left label,
    & .right label,
    & .wide label {
      color: #777;
      float: left;
      font-size: 11px;
      margin: 0 7px 3px 0;
      padding: 0 0 0 3px;
      width: 197px;
    }

    & input.textfield {
      margin: 0;
      padding: 3px;
      width: 192px;

      &.wide {
        width: 300px;
      }
    }
  }

  &.checkbox {
    & .left {
      float: left;
      margin: 0 7px 0 0;
      padding: 0;
      width: 20px;
    }

    & .right {
      float: left;
      margin: 0 7px 0 0;
      padding: 0;
      width: 380px;

      & label {
        color: #777;
        float: left;
        font-size: 11px;
        margin: 3px 0 0;
        padding: 0;
        width: 380px;
      }
    }
  }

  &.select {
    & .left,
    & .right {
      float: left;
      margin: 0 7px 0 0;
      padding: 0;
      width: 200px;
    }

    & .left label,
    & .right label {
      color: #777;
      float: left;
      font-size: 11px;
      margin: 0 7px 3px 0;
      padding: 0 0 0 3px;
      width: 197px;
    }

    & select {
      margin: 0;
      padding: 0;
      width: 200px;
      height: 17px;
      background-color: #fff;
    }
  }

  &.textarea {
    float: left;
    width: 484px;

    & label {
      color: #777;
      float: left;
      font-size: 11px;
      margin: 0 80px 3px 0;
      padding: 0 0 0 3px;
      width: 401px;
    }

    & textarea.textarea {
      border-style: none;
      float: left;
      height: 197px;
      margin: 0 77px 0 0;
      padding: 3px;
      width: 401px;
    }
  }

  &.submit {
    line-height: 0;
    margin: 5px 0 7px;
    padding: 0;
    text-align: right;

    & .left {
      float: left;
      margin: 0;
      padding: 0;
      width: 350px;
    }

    & .right {
      float: right;
      margin: 0;
      padding: 0;
      width: 120px;
    }

    & .hint {
      color: #777;
      font-size: 11px;
      line-height: 14px;
      margin: 0;
      padding: 0 0 0 3px !important;
      width: 350px;

      & a {
        color: #777;

        &:hover {
          color: #f1956f;
        }
      }
    }

    & input {
      float: right;
    }
  }
}

#participant_address {
  height: 100px;
}

.warning,
.error {
  color: #bf0929;
  display: block;
  font-size: 11px;
  font-weight: bold;
  margin: 0;
  padding: 2px 0 0 3px;
}

.warning {
  color: rgb(243, 149, 107);
}

abbr[title] {
  border: 0;
  cursor: help;
}

.wishlist-background {
  background: url("wichtel_geschenkliste.png") 363px 0 no-repeat;
  float: left;
  margin: 0;
  padding: 0;
  width: 484px;
}

.self-registration {
  & .link {
    padding-bottom: 5px;
    width: 490px;

    & a {
      color: #000;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        color: #f1956f;
      }
    }
  }
}

.registration .wishlist-background {
  background-position: 363px 40px;
}

/* Große Buttons */

.bigbuttons {
  float: left;
  margin: 0 0 15px;
  padding: 0;
  width: 100%;

  &.cancel {
    margin: 10px 0 15px;
  }
}

a.bigbutton,
div.bigbutton {
  background: url("bg_bigbutton.png") 0 0 no-repeat;
  color: #888;
  cursor: pointer;
  display: block;
  float: left;
  font-size: 11px;
  height: 22px;
  line-height: 11px;
  margin: 0 4px 0 0;
  overflow: hidden;
  padding: 7px 7px 0 5px;
  text-align: center;
  text-decoration: none;
  width: 153px;

  &.wide {
    background-image: url("bg_bigbutton_wide.png");
    width: 226px;
  }
}

.roundbuttons {
  height: 37px;
  left: 413px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 35px;
  width: 120px;
  z-index: 50;

  & a.homebutton,
  & a.reminder,
  & a.logout,
  & a.index,
  & a.profil {
    background: url("button_home.png") 0 0 no-repeat;
    display: block;
    float: right;
    height: 37px;
    margin: 0 0 0 3px;
    overflow: hidden;
    padding: 0;
    text-indent: -500px;
    width: 37px;

    &:hover {
      background-position: 0 -37px;
    }
  }

  & a.reminder {
    background-image: url("button_rundruf.png");
  }

  & a.logout {
    background-image: url("button_logout.png");
  }

  & a.index {
    background-image: url("button_index.png");
  }

  & a.profil {
    background-image: url("button_profil.png");
  }
}

/* Status-Buttons */

.statusbuttonset {
  float: left;
  line-height: 0;
  margin: 0 0 15px;
  padding: 0;
  position: relative;
  width: 100%;

  & fieldset {
    margin: 0;
  }

  & .wichtelillu {
    bottom: 0;
    height: 98px;
    left: 190px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 126px;
    z-index: 50;

    & img {
      left: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      z-index: 50;

      &.happy {
        left: -126px;
      }

      &.sad {
        left: -252px;
      }
    }
  }
}

#savehint {
  font-weight: bold;

  &.strong {
    color: #bf0929;
  }
}

.statusbuttons {
  float: left;
  margin: 0 0 5px;
  padding: 0 110px;
  width: 264px;
}

#button-yes,
#button-draw {
  background: url("button_yes.png") 0 0 no-repeat;
  float: left;
  height: 88px;
  margin: 0 88px 0 0;
  overflow: hidden;
  text-indent: -500px;
  width: 88px;

  &:hover,
  &.active {
    background-position: 0 -88px;
  }
}

#button-no,
#button-abort {
  background: url("button_no.png") 0 0 no-repeat;
  float: left;
  height: 88px;
  overflow: hidden;
  text-indent: -500px;
  width: 88px;

  &:hover,
  &.active {
    background-position: 0 -88px;
  }
}

/* Auslosungs-Button */

.readyandgo-frame {
  float: left;
  height: 125px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 504px;
}

#readyandgo {
  float: left;
  margin: 7px 0 0;
  padding: 0;
  width: 504px;

  & a.drawing {
    background: url("button_auslosung.png") 0 0 no-repeat;
    display: block;
    float: left;
    height: 80px;
    margin: 0 125px;
    overflow: hidden;
    padding: 0;
    text-indent: -500px;
    width: 254px;

    &:hover {
      background-position: 0 -80px;
    }
  }
}

#reallybuttonset {
  display: none;
  float: left;
  left: 0;
  position: absolute;
  top: 0;
  width: 504px;
  z-index: 100;

  & fieldset h3 {
    margin: 0;
    width: 150px;
  }

  & .statusbuttons {
    padding: 0 0 0 40px;
    width: 294px;
  }
}

#pleasewait {
  display: none;
  float: left;
  left: 0;
  position: absolute;
  top: 0;
  width: 504px;
  z-index: 150;

  & .greybox h3 {
    margin: 0;
    width: 170px;
  }

  & .waitanim {
    bottom: 16px;
    height: 59px;
    left: 207px;
    line-height: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 156px;
    z-index: 250;
  }

  & .wichtelillu-auslosung {
    bottom: 0;
    height: 96px;
    left: 200px;
    line-height: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 288px;
    z-index: 200;
  }
}

#reallybuttonset fieldset,
#pleasewait .greybox {
  height: 93px;
  margin: 0;
}

#button-draw {
  margin: 0 50px 0 0;
}

.greybox {
  & table,
  & p {
    margin: 0 0 5px;
  }
}

/* Hinweis-Meldungen */

.messages {
  height: 31px;
  left: 21px;
  overflow: hidden;
  position: absolute;
  top: 26px;
  width: 558px;
}

.notice {
  background: url("bg_message_glossy_ok.png") no-repeat;
  color: #d8efd8;
  font-size: 12px;
  height: 23px;
  line-height: 12px;
  padding: 8px 40px 0;
  text-align: center;
  width: 478px;

  &.alert {
    background-image: url("bg_message_glossy_warning.png");
    color: #f2dce0;
  }
}

/* Spezielle Elemente */

.adframe {
  background: url("bg_adframe.png") 0 0 no-repeat;
  float: left;
  height: 60px;
  left: -28px;
  margin: 30px 0 0;
  padding: 10px 28px;
  position: relative;
  text-align: center;
  width: 504px;

  &.en {
    background: url("bg_adframe_en.png") 0 0 no-repeat;
  }

  & ins {
    background: none;
    width: 468px;
    height: 60px;
    margin-left: 0 !important;
  }

  & iframe {
    text-align: left;
  }
}

.starten {
  left: 414px;
  position: absolute;
  top: 50px;
  z-index: 50;
}

.sogehts {
  left: 415px;
  position: absolute;
  top: 100px;
  z-index: 50;
}

.wichtelillu-auslosung,
.wichtelillu-fehler {
  float: left;
  margin: 0 0 50px;
  padding: 0;
  text-align: center;
  width: 504px;
}

#party_company {
  display: none;
}

.youtube-player {
  border: 0 none;
  margin: 0 0 15px;
}

/* SVG Graphs */
#party_container,
#participant_container {
  clear: both;
  height: 410px;
  margin: 0;
  padding: 0;
  width: 500px;
}
